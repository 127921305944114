<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-card>
          <b-carousel
            id="carousel-example-generic"
            controls
            indicators
          >
            <b-carousel-slide>
              <img
                slot="img"
                class="img-fluid w-100 d-block"
                src="@/assets/images/slider/slide1.jpg"
                alt="image slot"
                loading="lazy"
              >
            </b-carousel-slide>

            <b-carousel-slide>
              <img
                  slot="img"
                  class="img-fluid w-100 d-block"
                  src="@/assets/images/slider/slide6.jpg"
                  alt="image slot"
                  loading="lazy"
              >
            </b-carousel-slide>

            <b-carousel-slide>
              <img
                slot="img"
                class="img-fluid w-100 d-block"
                src="@/assets/images/slider/slide2.jpg"
                alt="image slot"
                loading="lazy"
              >
            </b-carousel-slide>

            <b-carousel-slide>
              <img
                slot="img"
                class="img-fluid w-100 d-block"
                src="@/assets/images/slider/slide3.jpg"
                alt="image slot"
                loading="lazy"
              >
            </b-carousel-slide>

            <b-carousel-slide>
              <img
                slot="img"
                class="img-fluid w-100 d-block"
                src="@/assets/images/slider/slide5.jpg"
                alt="image slot"
                loading="lazy"
              >
            </b-carousel-slide>
          </b-carousel>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-block">
      <b-col
        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <div class="icon_item">
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'recipientes-termicos/hieleras' } }">
              <div class="icon-img-home hieleras-img" />
              <p class="text-item">
                Hieleras
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'textiles/playeras' } }">
              <div class="icon-img-home textiles-img" />
              <p class="text-item">
                Textiles
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'recipientes-termicos/termo' } }">
              <div class="icon-img-home termos-img" />
              <p class="text-item">
                Termos
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'mochilas-y-maletas/mochilas' } }">
              <div class="icon-img-home mochilas-img" />
              <p class="text-item">
                Mochilas
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'electronicos/bocinas' } }">
              <div class="icon-img-home bocinas-img" />
              <p class="text-item">
                Bocinas
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'electronicos/powerbank' } }">
              <div class="icon-img-home power-img" />
              <p class="text-item">
                Power Bank
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'herramientas-manuales/kit-de-herramientas' } }">
              <div class="icon-img-home herramientas-img" />
              <p class="text-item">
                Herramientas
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'exclusivos/exclusivos-lth' } }">
              <div class="icon-img-home exclusivos-img" />
              <p class="text-item">
                Exclusivos
              </p>
            </b-link>
          </div>
          <div class="item">
            <b-link :to="{ name: 'products', params: { category: 'otros/otros' } }">
              <div class="icon-img-home otros-img" />
              <p class="text-item">
                Otros
              </p>
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col

        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-card>

          <b-row>
            <b-col
              lg="5"
              md="12"
              class="mb-box-home"
            >
              <b-card
                text-variant="white"
                class="h-100 bg-red-1"
              >

                <div class="box-text  d-flex align-items-center">
                  <div class="box-t">
                    <h1 class="text-white mt-1">
                      LISTO PARA LA OFICINA
                    </h1>

                    <p class="mt-2">
                      Estrena esta temporada, elige
                      entre todas las categorías
                      tus artículos preferidos, y nosotros
                      nos encargamos de llevarlo
                      hasta tu hogar.
                    </p>

                    <p>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="text-white btn-white-red "
                        :to="{ name: 'product-details', params: { slug: 'humidificador-de-ambiente' } }"
                      >
                        Comprar
                      </b-button>
                    </p>
                  </div>
                </div>

              </b-card>
            </b-col>
            <b-col
              lg="7"
              md="12"
            >
              <img
                loading="lazy"
                src="@/assets/images/home/muestra1.jpg"
                alt=""
                class="w-100"
              >
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col

        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-card>

          <b-row>
            <b-col
              lg="7"
              md="12"
              class="mb-box-home"
            >
              <img
                loading="lazy"
                src="@/assets/images/home/muestra2.jpg"
                alt=""
                class="w-100"
              >
            </b-col>
            <b-col
              lg="5"
              md="12"
            >
              <b-card
                text-variant="white"
                class="h-100 bg-red-2"
              >

                <div class="box-text">
                  <div class="box-t">
                    <h1 class="text-white mt-1">
                      TERMOS PARA TÍ
                    </h1>

                    <p class="mt-2">
                      Encuentra gran variedad de
                      productos exclusivos y útiles para
                      tu oficina u hogar.
                    </p>

                    <p>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="text-white btn-white-red"
                        :to="{ name: 'products', params: { category: 'recipientes-termicos/termo' } }"
                      >
                        Comprar
                      </b-button>
                    </p>
                  </div>
                </div>

              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="d-block">
      <b-col
        lg="12"
        xl="10"
        class="d-block m-auto text-center"
      >
        <h1 class="title-products mt-1 mb-3">
          PRODUCTOS DESTACADOS
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col

        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-row>
          <b-col
            xl="4"
            lg="6"
            md="12"
            class="mb-3"
          >
            <b-card
              text-variant="white"
              class="h-100 bg-red-2"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/herramientas.png')})`}"
                  />
                  <h2 class="text-white mt-3">
                    JUEGO DE HERRAMIENTAS NASCAR
                  </h2>

                  <h3 class="text-white text-box-title">
                    $421

                  </h3>

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-1"
                      :to="{ name: 'product-details', params: { slug: 'juego-de-herramientas' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>
          <b-col
            xl="4"
            lg="6"
            md="12"
            class="mb-3"
          >
            <b-card
              text-variant="white"
              class="h-100 bg-red-2"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/mochila.png')})`}"
                  />
                  <h2 class="text-white mt-3">
                    MOCHILA WINNIPEG

                  </h2>

                  <h3 class="text-white text-box-title">
                    $890
                  </h3>

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-1"
                      :to="{ name: 'product-details', params: { slug: 'mochila-winnipeg' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

          <b-col
            xl="4"
            lg="6"
            md="12"
            class="mb-3"
          >
            <b-card
              text-variant="white"
              class="h-100 bg-red-2"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/taza.png')})`}"
                  />
                  <h2 class="text-white mt-3">
                    TAZA TAMMY

                  </h2>

                  <h3 class="text-white text-box-title">
                    $367
                  </h3>

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-1"
                      :to="{ name: 'product-details', params: { slug: 'taza-tammy' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

        </b-row>
      </b-col>

    </b-row>

    <b-row class="d-block">
      <b-col
        lg="12"
        xl="10"
        class="d-block m-auto text-center"
      >
        <h1 class="title-products mt-1 mb-3">
          LO MÁS VENDIDO
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col

        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-row>
          <b-col
            lg="3"
            md="12"
            class="mb-3"
          >
            <b-card
              bg-variant="primary"
              text-variant="white"
              class="h-100"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product-2 img-product-2-s-1"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/termo1.png')})`}"
                  />

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-2"
                      :to="{ name: 'product-details', params: { slug: 'termo-neris' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

          <b-col
            lg="3"
            md="12"
            class="mb-3"
          >
            <b-card
              bg-variant="primary"
              text-variant="white"
              class="h-100"
            >
              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product-2 img-product-2-s-2"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/power.png')})`}"
                  />

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-2"
                      :to="{ name: 'product-details', params: { slug: 'power-bank-cetus' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

          <b-col
            lg="3"
            md="12"
            class="mb-3"
          >
            <b-card
              bg-variant="primary"
              text-variant="white"
              class="h-100"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product-2 img-product-2-s-3"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/usb.png')})`}"
                  />

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-2"
                      :to="{ name: 'product-details', params: { slug: 'presentador-touch' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

          <b-col
            lg="3"
            md="12"
            class="mb-3"
          >
            <b-card
              bg-variant="primary"
              text-variant="white"
              class="h-100"
            >

              <div class="box-text">
                <div class="box-t text-center">
                  <div
                    class="img-product-2 img-product-2-s-3"
                    :style="{backgroundImage: `url(${require('@/assets/images/home/caja.png')})`}"
                  />

                  <p class="mb-0">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-white btn-white-blue mt-2"
                      :to="{ name: 'product-details', params: { slug: 'set-de-herramientas-conrad' } }"
                    >
                      Comprar
                    </b-button>
                  </p>
                </div>
              </div>

            </b-card>
          </b-col>

        </b-row>
      </b-col>

    </b-row>

    <b-row>
      <b-col

        lg="12"
        xl="10"
        class="d-block m-auto"
      >
        <b-card>

          <b-row>
            <b-col
              lg="5"
              md="12"
              class="mb-box-home"
            >
              <b-card
                text-variant="white"
                class="h-100 bg-red-1"
              >

                <div class="box-text">
                  <div class="box-t">
                    <h1 class="text-white mt-1">
                      TEXTILES
                    </h1>

                    <p class="mt-2">
                      Productos muy solicitados
                      y con satisfacción de cliente
                      recibida.
                    </p>

                    <p>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="text-white btn-white-red "
                        :to="{ name: 'products', params: { category: 'textiles/playeras' } }"
                      >
                        Comprar
                      </b-button>
                    </p>
                  </div>
                </div>

              </b-card>
            </b-col>
            <b-col
              lg="7"
              md="12"
            >
              <img
                loading="lazy"
                src="@/assets/images/home/muestra4.jpg"
                alt=""
                class="w-100"
              >
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCarousel, BCarouselSlide, BCard, BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
.icon_item{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  justify-content: center;
  .item {
    width: calc(100% / 9);
    margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
    color: #1d4079;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    &:hover{
      -webkit-filter: drop-shadow(0px 0px 30px rgba(29, 64, 121));
    }
  }
  img{
    width: 65px;
    display: block;
    margin: 0 auto;
  }

}
.max-he-home{
  max-height: 253px;
}
.box-text {
  display: block;
  margin: 0 auto;
}
.btn-white-red{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #ea5455!important;
  font-weight: 700;
}

.btn-white-blue{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: rgba(29,64,121,1)!important;
  font-weight: 700;
}

.bg-red-1{
  background: rgb(176,30,53);
  background: linear-gradient(149deg, rgba(219,3,40,1) 0%, rgba(29,64,121,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}

.bg-red-2{
  background: rgb(219,3,40);
  background: linear-gradient(149deg, rgba(219,3,40,1) 0%, rgba(29,64,121,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}

.title-products{
  font-size: 40px;
  font-weight: 700;
  color: #1d4079;
}
.dark-layout{
  .title-products{
    font-size: 40px;
    font-weight: 700;
    color: $theme-dark-headings-color;
  }
}
.dot {
  width: 400px; height: 400px;
  background: radial-gradient(ellipse at center, #fff,rgba(0,0,0,.0));
}
.img-product {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}

.img-product-2 {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin: 0 auto;
}
.img-product-2-s-1{
  background-size: 90px auto;
}

.img-product-2-s-2{
  background-size: auto 105px;
}

.img-product-2-s-3{
  background-size: auto 150px;
}

.yourImg {
  border-radius: 50%;
}
.text-box-title{
  font-size: 30px;
}
.box-image{
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
}

.dark-layout{
  .icon-img-home{
    background-color: #d0d2d6;
  }
  .text-item{
    color: #d0d2d6!important;
  }
}

.icon-img-home{
  width: 100%;
  height: 65px;
  background-color: #1d4079;
}

.hieleras-img{
  mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
}

.textiles-img{
  mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
}

.termos-img{
  mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
}

.mochilas-img{
  mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
}

.bocinas-img{
  mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
}

.power-img{
  mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
}

.herramientas-img{
  mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
}
.exclusivos-img{
  mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
}

.otros-img{
  mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
}

@media (min-width:1200px) and (max-width: 1480px) {
  .img-product{
    width: 245px!important;
    height: 245px!important;
    background-size: 120px!important;
  }
}
@media (max-width:991px){
  .mb-box-home{
    margin-bottom: 30px;
  }
  .icon_item {
    .item{
      width: calc(100% / 5);
    }
  }
}

@media (max-width:576px){
  .icon_item {
    .item{
      width: calc(100% / 3);
    }
  }
}

</style>
